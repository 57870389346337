





































































































import { Observer } from 'mobx-vue'
import { Component, Inject, Ref, Vue } from 'vue-property-decorator'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import { AppProvider } from '@/app-providers'
import { IdoPoolsViewModel } from '../viewmodels/ido-pools-viewmodel'

@Observer
@Component({
  components: {
    Swiper,
    SwiperSlide,
    PoolCover: () => import('@/components/images/pool-cover.vue'),
  },
})
export default class TrailerVideos extends Vue {
  @Inject() providers!: AppProvider
  @Inject() vm!: IdoPoolsViewModel
  @Ref('trailerVideo') trailerVideo: any
  @Ref('swiper') swiper: any
  isMuted = true
  playAudio() {
    this.isMuted = !this.isMuted
  }
  swiperOption = {
    direction: 'vertical',
    mousewheel: true,
    breakpoints: {
      0: { slidesPerView: 2 },
      850: { slidesPerView: 2 },
      960: {
        slidesPerView: 3,
      },
      1100: {
        slidesPerView: 4,
      },
    },
    on: {
      click: (item) => {
        this.vm.changeActiveVideo(item.clickedIndex)
      },
    },
  }

  goToProjectDetail() {
    this.$router.push(`/project/${this.vm.activeTrailerPool.pool.unicodeName}`)
  }

  activeIndexNext() {
    this.vm.changeActiveVideo(this.vm.activeVideoIndex + 1)
  }
  activeIndexBack() {
    this.vm.changeActiveVideo(this.vm.activeVideoIndex - 1)
  }
}
